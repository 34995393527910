class GloITQuickLoginCard extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		//var modal = document.getElementById('modalCenter');
		//UIkit.modal(modal).show();
	}

	afterLoad() {
		this.$el = $(this);
	}

	beforeUpdate() {
	}

	afterUpdate() {
	}

	unload() {
	}
}

!customElements.get('bat-card-gloitquickloginmodal') &&
	customElements.define('bat-card-gloitquickloginmodal', GloITQuickLoginCard);
